(function () {
    angular.module('informaApp')
        .service('SummaryChartTypes', SummaryChartTypes);

    function SummaryChartTypes() {
        return {
            barChart: 0,
            trendsView: 1,
            ganttChart: 2,
            boxPlotChart: 3
        };
    }
})();